






























import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import { dispatchCheckLoggedIn } from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import { readUserProfile } from "@/store/main/getters";

@Component({
  components: { Header, Footer },
})
export default class Contact extends Vue {
  public async mounted() {
    await dispatchCheckLoggedIn(this.$store);
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
